<template>
  <div class="select-doc">
    <div>
      <p class="fs-28 fw-500 txt-black mb-3 text-center"> {{doc_type === 1 ? 'Sổ khám bệnh' : doc_type === 2 ? 'Đơn thuốc' :doc_type === 3 ? 'Xét nghiệm' : ''}}</p>
    </div>
    <!-- item -->
    <div class="row">
      <div
        class="col-xl-3 col-lg-4 col-sm-6 mb-3"
        @click="modalUpload(true)"
      >
        <div class="p-3 radius-10 bg-white c-pointer">
          <div class="upload item-doc radius-10">
            <div class="ratio ratio-16x9 upload-image">
            </div>
            <div class="bottom-item">
              <div class="w-100 text-center mb-1">
                <button class="btn btn-pri fs-10 mb-0">
                  <svg
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="5.42773"
                      y="5.95605"
                      width="6.80812"
                      height="7.56458"
                      fill="#476DD6"
                    />
                    <path
                      d="M9.21008 2.17383C5.03444 2.17383 1.64551 5.56276 1.64551 9.7384C1.64551 13.914 5.03444 17.303 9.21008 17.303C13.3857 17.303 16.7747 13.914 16.7747 9.7384C16.7747 5.56276 13.3857 2.17383 9.21008 2.17383ZM11.8804 9.7611C11.7669 9.87457 11.6232 9.92752 11.4795 9.92752C11.3357 9.92752 11.192 9.87457 11.0785 9.7611L9.77743 8.45999V12.386C9.77743 12.6962 9.52023 12.9533 9.21008 12.9533C8.89994 12.9533 8.64274 12.6962 8.64274 12.386V8.45999L7.34163 9.7611C7.12226 9.98047 6.75916 9.98047 6.53979 9.7611C6.32042 9.54172 6.32042 9.17863 6.53979 8.95925L8.80916 6.68988C9.02853 6.47051 9.39163 6.47051 9.61101 6.68988L11.8804 8.95925C12.0998 9.17863 12.0998 9.54172 11.8804 9.7611Z"
                      fill="white"
                    />
                  </svg>
                  Tải lên
                </button>
              </div>
              <p class="w-100 text-center fs-10 mb-1">
                File hỗ trợ: <span class="txt-pri">Hình ảnh, PDF, Doc, Docx, Xlsx</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <template v-for="(doc, i) in filteredCurrDocs">
        <template v-if="Object.keys(doc.document_info).length">
          <div
            class="col-xl-3 col-lg-4 col-sm-6 mb-3"
            :key="`doc_child_${idx}_${uuidv4()}`"
            v-for="(docChild, idx) in doc.document_info.document_link"
          >
            <div class="p-3 radius-10 bg-white item-wraper">
              <div
                class="item-doc radius-10"
                :set="[extInfo = getExtInfo(docChild.url), link_dow = getImageUrl(docChild.url)]"
              >
                <a
                  v-if="extInfo.is_image"
                  target="_blank"
                  :href="link_dow"
                  :download="doc.name"
                >
                  <div
                    class="ratio ratio-16x9 img-thumb"
                    :style="{ 'background-image': 'url(' + link_dow + ')' }"
                  ></div>
                </a>
                <a
                  class=""
                  v-else
                  target="_blank"
                  :href="link_dow"
                  :download="`${doc.name}_${docChild.title}`"
                >
                  <div class="ratio ratio-16x9 bg-light">
                    <div class=" d-flex justify-content-center align-items-center h-100">
                      <p class="mb-0 fs-36 txt-pri text-uppercase">{{extInfo.ext}}</p>
                    </div>
                  </div>
                </a>
                <div class="bottom-item pt-2">
                  <p class="mb-0 fs-14">{{docChild.updated_at}}{{docChild.type}} - {{doc.content}}</p>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div
          class="col-xl-3 col-lg-4 col-sm-6 mb-3"
          :key="`doc_${i}_${uuidv4()}`"
          v-else
        >
          <div class="p-3 radius-10 bg-white item-wraper">
            <div
              class="item-doc radius-10"
              :set="[extInfo = getExtInfo(doc.link), link_dow = getImageUrl(doc.link)]"
            >
              <a
                v-if="extInfo.is_image"
                target="_blank"
                :href="link_dow"
                :download="doc.name"
              >
                <div
                  class="ratio ratio-16x9 img-thumb"
                  :style="{ 'background-image': 'url(' + link_dow + ')' }"
                ></div>
              </a>
              <a
                class=""
                v-else
                target="_blank"
                :href="link_dow"
                :download="doc.name"
              >
                <div class="ratio ratio-16x9 bg-light">
                  <div class=" d-flex justify-content-center align-items-center h-100">
                    <p class="mb-0 fs-36 txt-pri text-uppercase">{{extInfo.ext}}</p>
                  </div>
                </div>
              </a>
              <div class="bottom-item pt-2">
                <p class="mb-0 fs-14">{{doc.content}}</p>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div
        class="col-xl-3 col-lg-4 col-sm-6 mb-3"
        v-for="(doc, i) in filteredDocs"
        :key="'local'+i"
      >
        <div class="p-3 radius-10 bg-white item-wraper">
          <div
            class="item-doc radius-10"
            :set="extInfo = getLocalExtInfo(doc.file)"
          >
            <div
              class="ratio ratio-16x9 img-thumb"
              :style="{ 'background-image': 'url(' + getBase64URL(doc.file) + ')' }"
              v-if="extInfo.is_image"
            >
            </div>
            <div
              class="ratio ratio-16x9 bg-light"
              v-if="!extInfo.is_image"
            >
              <div class=" d-flex justify-content-center align-items-center h-100">
                <p class="mb-0 fs-36 txt-pri text-uppercase">{{extInfo.ext}}</p>
              </div>
            </div>
            <div class="bottom-item pt-2">
              <p class="mb-0 fs-14">{{doc.content}}</p>
            </div>
          </div>
          <div
            class="delete-btn p-0 radius-10 c-pointer"
            @click="onDeleteDoc(doc, i)"
          >
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="30"
                height="30"
                rx="6"
                fill="#DDE6F5"
              />
              <path
                d="M24.0697 8.23C22.4597 8.07 20.8497 7.95 19.2297 7.86V7.85L19.0097 6.55C18.8597 5.63 18.6397 4.25 16.2997 4.25H13.6797C11.3497 4.25 11.1297 5.57 10.9697 6.54L10.7597 7.82C9.82967 7.88 8.89967 7.94 7.96967 8.03L5.92967 8.23C5.50967 8.27 5.20967 8.64 5.24967 9.05C5.28967 9.46 5.64967 9.76 6.06967 9.72L8.10967 9.52C13.3497 9 18.6297 9.2 23.9297 9.73C23.9597 9.73 23.9797 9.73 24.0097 9.73C24.3897 9.73 24.7197 9.44 24.7597 9.05C24.7897 8.64 24.4897 8.27 24.0697 8.23Z"
                fill="#476DD6"
              />
              <path
                d="M22.2297 11.14C21.9897 10.89 21.6597 10.75 21.3197 10.75H8.67975C8.33975 10.75 7.99975 10.89 7.76975 11.14C7.53975 11.39 7.40975 11.73 7.42975 12.08L8.04975 22.34C8.15975 23.86 8.29975 25.76 11.7897 25.76H18.2097C21.6997 25.76 21.8397 23.87 21.9497 22.34L22.5697 12.09C22.5897 11.73 22.4597 11.39 22.2297 11.14ZM16.6597 20.75H13.3297C12.9197 20.75 12.5797 20.41 12.5797 20C12.5797 19.59 12.9197 19.25 13.3297 19.25H16.6597C17.0697 19.25 17.4097 19.59 17.4097 20C17.4097 20.41 17.0697 20.75 16.6597 20.75ZM17.4997 16.75H12.4997C12.0897 16.75 11.7497 16.41 11.7497 16C11.7497 15.59 12.0897 15.25 12.4997 15.25H17.4997C17.9097 15.25 18.2497 15.59 18.2497 16C18.2497 16.41 17.9097 16.75 17.4997 16.75Z"
                fill="#476DD6"
              />
            </svg>
          </div>
        </div>
      </div>
      <div class="col-12 text-center">
        <button
          class="btn radius-10 btn-pri btn-finish"
          @click="goToCareTeam"
        >Quay lại</button>
      </div>
    </div>
    <!-- item -->

    <!-- Modal -->
    <div
      class="modal fade"
      id="modalUpload"
      tabindex="-1"
      aria-labelledby="modalUploadTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-sm modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header border-0">
            <h5
              class="modal-title fs-18 txt-black fw-500"
              id="modalUploadTitle"
            >Tải lên tệp</h5>
            <!-- <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button> -->
          </div>
          <div class="modal-body">
            <label class="fs-12 txt-black">Tên tài liệu <span class="text-danger">*</span></label>
            <input
              type="text"
              class="form-control txt-pri mb-0"
              v-model="name"
              placeholder="Nhập tên tài liệu ...."
              @input="err_name = ''"
            >
            <p class="mb-3 fs-12 text-danger">{{err_name}}</p>
            <p
              class="mb-2 fs-14 txt-pri"
              :set="count = selected_docs.length"
            ><span v-if="count">Đã chọn {{count}} files</span></p>
            <div
              class="upload-btn"
              @click="openInputDoc"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14.7369 2.76175H8.08489C6.00489 2.75375 4.30089 4.41075 4.25089 6.49075V17.2277C4.20589 19.3297 5.87389 21.0697 7.97489 21.1147C8.01189 21.1147 8.04889 21.1157 8.08489 21.1147H16.0729C18.1629 21.0407 19.8149 19.3187 19.8029 17.2277V8.03775L14.7369 2.76175Z"
                  stroke="#476DD6"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.4756 2.75V5.659C14.4756 7.079 15.6246 8.23 17.0446 8.234H19.7986"
                  stroke="#476DD6"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.6416 9.90918V15.9502"
                  stroke="#476DD6"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.9869 12.2642L11.6419 9.90918L9.29688 12.2642"
                  stroke="#476DD6"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <span class="fs-14 fw-500 txt-pri">Tải lên hình ảnh, PDF, Doc, Docx, Xlsx</span>
            </div>
            <p class="mb-3 fs-12 text-danger">{{err_file}}</p>
          </div>
          <div class="modal-footer border-0 justify-content-center">
            <button
              type="button"
              class="btn btn-pri radius-10 w-100"
              @click="onFinishUpload()"
            >Tải lên</button>
          </div>
        </div>
      </div>
    </div>
    <input
      type="file"
      ref="inputFile"
      accept=".jpeg, .png, .jpg, .gif, .svg, .doc, .docx, .pdf, .xls, .xlsx"
      @change="onSelectDoc($event)"
      multiple
      class="d-none"
    >

  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import appUtils from '../../utils/appUtils'
export default {
  name: 'SelectDocument',
  props: ['doc_type', 'documents', 'current_documents'],
  data () {
    return {
      exts: ['jpeg', 'png', 'jpg', 'gif', 'svg', 'doc', 'docx', 'pdf', 'xls', 'xlsx'],
      selected_docs: [],
      name: '',
      err_name: '',
      err_file: '',
      img_exts: ['jpeg', 'png', 'jpg', 'gif', 'svg'],
      uuidv4
    }
  },
  computed: {
    filteredDocs () {
      return this.documents ? this.documents.filter((item) => item.type === this.doc_type) : []
    },
    filteredCurrDocs () {
      const data = this.current_documents ? this.current_documents.filter((item) => item.type === this.doc_type) : []
      return data.map((item) => {
        const document_info = item?.document_link && appUtils.isJSON(item?.document_link) ? JSON.parse(item.document_link) : {}
        return {
          ...item,
          document_info
        }
      })
      // return this.current_documents ? this.current_documents.filter((item) => item.type === this.doc_type) : []
    }
  },
  mounted () {

  },
  methods: {
    goToCareTeam () {
      this.$emit('hideSelectDoc')
    },
    openInputDoc () {
      this.$refs.inputFile.click()
    },
    onSelectDoc (e) {
      const self = this
      const files = e.target.files
      self.err_file = ''
      console.log(files)
      let errFile = ''
      if (files) {
        for (let i = 0; i < files.length; i++) {
          const f = files[i]
          if (this.validateFile(f.name.split('.').pop())) {
            self.selected_docs.push(f)
          } else {
            errFile += (i + 1) + ','
          }
        }
      }
      self.handleClearInput()
      if (errFile) alert('File số ' + errFile.substring(0, errFile.length - 1) + ' không đúng định dạng nên đã bị loại bỏ')
    },
    validateFile (ext) {
      return this.exts.findIndex(e => e === ext) > -1
    },
    isImage (ext) {
      return this.img_exts.findIndex(e => e === ext) > -1
    },
    onFinishUpload () {
      if (!this.name) {
        this.err_name = 'Vui lòng nhập tên tài liệu'
        return
      }
      if (!this.selected_docs || !this.selected_docs.length) {
        this.err_file = 'Vui lòng chọn tài liệu'
        return
      }
      this.finishUpload(this.selected_docs, this.name)
      this.modalUpload(false)
      this.name = ''
      this.selected_docs = []
    },
    finishUpload (files, name) {
      this.$emit('selectedImage', files, name)
    },
    onDeleteDoc (doc, i) {
      const r = confirm('Bạn có chắc chắn muốn xóa file này')
      if (!r) return
      this.$emit('onDeleteDoc', doc, i)
      // window.event.stopPropagation()
    },
    getBase64URL (file) {
      return URL.createObjectURL(file)
    },
    getImageUrl (path) {
      if (path.startsWith('http')) {
        return path
      }
      return appUtils.getDocumentURL(path)
    },
    modalUpload (show) {
      window.$('#modalUpload').modal(show ? 'show' : 'hide')
    },
    getLocalExtInfo (doc) {
      const result = {
        is_image: false,
        ext: ''
      }
      if (this.isImage(doc.name.split('.').pop())) {
        result.is_image = true
      } else {
        result.ext = doc.name.split('.').pop()
      }
      return result
    },
    getExtInfo (link) {
      const result = {
        is_image: false,
        ext: ''
      }
      if (this.isImage(link.split('.').pop())) {
        result.is_image = true
      } else {
        result.ext = link.split('.').pop()
      }
      return result
    },
    handleClearInput () {
      this.$refs.inputFile.value = null
    }
  }
}
</script>

<style scoped>
.select-doc {
  padding: 10px 24px;
  background-color: #f7f7f9;
  min-height: calc(100vh - 75px);
}
.delete-btn {
  position: absolute;
  top: 5px;
  right: 5px;
}
.upload {
  border: 0.756458px dashed #20409b;
}
.upload-btn {
  width: 100%;
  border: 0.756458px dashed #20409b;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-radius: 10px;
  cursor: pointer;
}
.form-control {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  color: #476dd6;
  padding-left: 0;
}
.upload-image {
  background-image: url("../../../public/icons/upload-doc.svg");
  background-repeat: no-repeat;
  background-position: center;
}
.bottom-item {
  height: 55px;
}
.item-wraper {
  position: relative;
}
.btn-finish {
  margin-top: 50px;
  height: 50px;
  min-width: 250px;
  max-width: 100%;
}
.img-thumb {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
