import { isMobile, isSafari, isTablet } from 'mobile-device-detect'

import { PRODUCT_TYPE } from './constant'
import Vue from 'vue'
import defaultImage from '@/assets/images/default.jpg'
import moment from 'moment/moment'
import slugify from 'slugify'

const mapLang = [
  { id: 1, code: 'vi' },
  { id: 2, code: 'en' }
]

const appUtils = {
  setLocalToken (token) {
    return localStorage.setItem('jwt_token', token)
  },
  getLocalToken () {
    return localStorage.getItem('jwt_token')
  },
  removeLocalToken () {
    localStorage.removeItem('jwt_token')
  },
  setLocalLang (lang) {
    return localStorage.setItem('lang', lang || 'vi')
  },
  getLocalLang () {
    return localStorage.getItem('lang')
  },
  removeLocalLang () {
    return localStorage.setItem('lang', 'vi')
  },

  getLocalUser: () =>
    localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user'))
      : null,
  removeLocalUser () {
    Vue.prototype.$user = null
    localStorage.removeItem('user')
  },
  setLocalUser: (user) => {
    Vue.prototype.$user = user
    localStorage.setItem('user', JSON.stringify(user))
  },
  onLogout () {
    const r = confirm('Bạn có chắc chắn muốn đăng xuất?')
    if (!r) return
    this.removeLocalUser()
    this.removeLocalToken()
    Vue.prototype.$user = null
    setTimeout(() => {
      window.$router
        .replace({
          name: 'ExaminationRegister'
        })
        .catch(() => { })
    }, 100)
  },
  getDocumentURL (path) {
    return process.env.VUE_APP_BASE_IMAGE_URL + 'storage/' + path
  },
  getPersonInfoString (p) {
    return (
      p.name +
      ' (' +
      this.getGender(p.gender) +
      ' - ' +
      this.getAges(p.birthday) +
      (p.address ? ' - ' + p.address : '') +
      ')'
    )
  },
  getGender (gender = 1) {
    return gender === 1 ? 'Nam' : gender === 2 ? 'Nữ' : 'Khác'
  },
  getAges (date) {
    if (!date) return ''
    const bd = moment(date, 'YYYY-MM-DD')
    const age = moment().diff(bd, 'years')
    let month = 0
    if (!age) {
      month = moment().diff(bd, 'months')
    }
    return age ? age + ' tuổi' : month + ' tháng tuổi'
  },
  removeVietnameseTones (str) {
    if (!str) return ''
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
    str = str.replace(/đ/g, 'd')
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A')
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E')
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I')
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O')
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U')
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y')
    str = str.replace(/Đ/g, 'D')
    // Some system encode vietnamese combining accent as individual utf-8 characters
    // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, '') // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, '') // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
    // Remove extra spaces
    // Bỏ các khoảng trắng liền nhau
    str = str.replace(/ + /g, ' ')
    str = str.trim()
    // Remove punctuations
    // Bỏ dấu câu, kí tự đặc biệt
    // str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, ' ')
    return str
  },
  replaceSpacesString (str) {
    return str.replace(/\s/g, '-')
  },
  addToCart (p, variant, count = 1) {
    const productIdx = Vue.prototype.$shoppingCart.findIndex(
      (item) => item.id === p.id && item.variant_id === variant
    )
    if (productIdx > -1) {
      Vue.prototype.$shoppingCart[productIdx].count = count + Vue.prototype.$shoppingCart[productIdx].count
      Vue.prototype.$shoppingCart[productIdx].total_amount =
        Vue.prototype.$shoppingCart[productIdx].count *
        Vue.prototype.$shoppingCart[productIdx].selected_variant?.price
    } else {
      const newCount = p.type === PRODUCT_TYPE.SERVICE ? 1 : count
      const product = {
        ...p,
        count: newCount,
        total_amount: newCount * p.selected_variant?.price,
        variant_id: variant
      }
      Vue.prototype.$shoppingCart.push(product)
    }
    localStorage.setItem(
      'HodoCart',
      JSON.stringify(Vue.prototype.$shoppingCart)
    )
  },
  subtractCart (p, count = 1) {
    const productIdx = Vue.prototype.$shoppingCart.findIndex(
      (item) => item.id === p.id
    )
    if (productIdx > -1) {
      Vue.prototype.$shoppingCart[productIdx].count -= count
      Vue.prototype.$shoppingCart[productIdx].total_amount =
        Vue.prototype.$shoppingCart[productIdx].count *
        Vue.prototype.$shoppingCart[productIdx].default_price
    }
    localStorage.setItem(
      'HodoCart',
      JSON.stringify(Vue.prototype.$shoppingCart)
    )
  },
  rmfromCart (pid, variant) {
    const productIdx = Vue.prototype.$shoppingCart.findIndex(
      (item) => item.id === pid && item.variant_id === variant
    )
    Vue.prototype.$shoppingCart.splice(productIdx, 1)
    localStorage.setItem(
      'HodoCart',
      JSON.stringify(Vue.prototype.$shoppingCart)
    )
  },
  removeAllCartData () {
    Vue.prototype.$shoppingCart = []
    localStorage.removeItem('HodoCart')
  },
  getInvoices (user_id) {
    return JSON.parse(localStorage.getItem(`Invoices-User#${user_id}`)) || []
  },
  setInvoices (data, user_id) {
    localStorage.setItem(`Invoices-User#${user_id}`, JSON.stringify(data))
  },
  removeInvoices (user_id) {
    localStorage.removeItem(`Invoices-User#${user_id}`)
  },
  numberFormat (number, currencyUnit) {
    let data = number
      ?.toString()
      .replace(/\D/g, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    // let data = number.toLocaleString(undefined, { minimumFractionDigits: 0 });
    if (currencyUnit) {
      data += ` ${currencyUnit}`
    }
    return data
  },
  formatDate (date) {
    return moment(date).format('YYYY-MM-DD')
  },
  formatTime (time) {
    return moment(time).format('HH:mm')
  },
  formatDateDDMMYYYY (date) {
    return moment(date).format('DD/MM/YYYY')
  },
  formatTimeDate (date) {
    return moment(date).format('HH:mm DD/MM/YYYY')
  },
  formatUTCTimeDate (date) {
    return moment.utc(date).format('HH:mm DD/MM/YYYY')
  },
  formatDateTime (date) {
    return moment(date).format('DD/MM/YYYY HH:mm')
  },
  formatInputDateTime (date) {
    return moment(date).format('YYYY-MM-DD HH:mm:ss')
  },
  formatInputUTCDateTime (date) {
    return moment(date).utc().format('YYYY-MM-DD HH:mm:ss')
  },
  formatGroupDateTime (date) {
    const dateFormat = moment.utc(date).format('DD/MM/YYYY')
    const startHour = moment.utc(date).hours()
    const endHour = moment.utc(date).add(1, 'hour').hours()
    return `${startHour > 9 ? startHour : `0${startHour}`}:00 - ${endHour > 9 ? endHour : `0${endHour}`
      }:00 - ${dateFormat}`
  },
  onHandleErrorLoadImage (e, customImage) {
    e.target.src = customImage || defaultImage
    e.onerror = null
  },
  truncateHash (string, startLength = 4, endLength = 4, separator = '...') {
    if (!string) return ''
    return `${string.substring(0, startLength)}${separator}${string.substring(
      string.length - endLength
    )}`
  },
  slugifyStr (str, replacement = '-') {
    if (!str || !str.length || typeof str === 'undefined') return
    return slugify(str, {
      replacement, // replace spaces with replacement character, defaults to `-`
      remove: /[*+~.()'"!:@]/g, // remove characters that match regex, defaults to `undefined`
      lower: true, // convert to lower case, defaults to `false`
      strict: true, // strip special characters except replacement, defaults to `false`
      locale: 'vi' // language code of the locale to use
    })
  },
  hidePhoneNumber (phoneNumber) {
    const first4Digits = phoneNumber?.slice(0, 4)
    const maskedNumber = first4Digits.padEnd(phoneNumber.length, '*')
    return maskedNumber
  },
  getImageURL (path) {
    return process.env.VUE_APP_BASE_IMAGE_URL + 'storage/' + path
  },
  checkIsMobile () {
    return isMobile
  },
  checkIsMobileAndTablet () {
    return isMobile || isTablet
  },
  checkIsSafari () {
    return isSafari
  },
  randomStr (length, isNumber) {
    var result = ''
    var characters = isNumber
      ? '0123456789'
      : 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    var charactersLength = characters.length
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  },
  numberToWords (number) {
    const defaultNumbers = ' hai ba bốn năm sáu bảy tám chín'

    const chuHangDonVi = ('1 một' + defaultNumbers).split(' ')
    const chuHangChuc = ('lẻ mười' + defaultNumbers).split(' ')
    const chuHangTram = ('không một' + defaultNumbers).split(' ')

    const convert_block_three = (number) => {
      if (number === '000') return ''
      var _a = number + ''

      switch (_a.length) {
        case 0:
          return ''
        case 1:
          return chuHangDonVi[_a]
        case 2:
          return convert_block_two(_a)
        case 3:
          var chuc_dv = ''

          if (_a.slice(1, 3) !== '00') {
            chuc_dv = convert_block_two(_a.slice(1, 3))
          }
          var tram = chuHangTram[_a[0]] + ' trăm'
          return tram + ' ' + chuc_dv
      }
    }

    const convert_block_two = (number) => {
      var dv = chuHangDonVi[number[1]]
      var chuc = chuHangChuc[number[0]]
      var append = ''

      if (number[0] > 0 && number[1] === 5) {
        dv = 'lăm'
      }
      if (number[0] > 1) {
        append = ' mươi'
        if (number[1] === 1) {
          dv = ' mốt'
        }
      }

      return chuc + '' + append + ' ' + dv
    }

    const dvBlock = '1 nghìn triệu tỷ'.split(' ')

    var str = parseInt(number) + ''
    var i = 0
    var arr = []
    var index = str.length
    var result = []
    var rsString = ''

    // eslint-disable-next-line no-use-before-define
    if (index === 0 || str === 'NaN') {
      return ''
    }

    while (index >= 0) {
      arr.push(str.substring(index, Math.max(index - 3, 0)))
      index -= 3
    }
    for (i = arr.length - 1; i >= 0; i--) {
      if (arr[i] !== '' && arr[i] !== '000') {
        result.push(convert_block_three(arr[i]))
        if (dvBlock[i]) {
          result.push(dvBlock[i])
        }
      }
    }
    rsString = result.join(' ')

    return (
      rsString.replace(/[0-9]/g, '').replace(/ /g, ' ').replace(/ $/, '') +
      ' đồng'
    )
  },
  formatImageBase64TiptapEditor (content) {
    if (!content) return ''

    const regex = /data:image\/[^;]+;base64[^"]+/
    const hasBase64Image = regex?.test(content)

    if (!hasBase64Image) return content

    let result = content.replace('src=\\', 'src=')

    if (!result.endsWith('" />')) {
      result += '" />'
    }
    return result
  },
  isDate (data) {
    return new Date(data) instanceof Date && !isNaN(new Date(data).getTime())
  },
  formatDateTimeFull (dateTime) {
    if (!dateTime) {
      return Vue.prototype.moment().format('HH:mm:ss DD/MM/YYYY')
    }
    return Vue.prototype.moment(dateTime).format('HH:mm:ss DD/MM/YYYY')
  },
  getLangCode (id) {
    if (!id || isNaN(id)) return
    const found = mapLang.find((l) => l.id === parseInt(id))
    return found?.code
  },
  getLangId (l) {
    if (!l) return
    const found = mapLang.find((item) => item.code === l)
    return found?.id
  },
  base64: {
    decode: (s) => window.btoa(unescape(encodeURIComponent(s))),
    encode: (b) => decodeURIComponent(escape(window.atob(b)))
  },
  convertSlug (text, replacement = '-') {
    if (!text || typeof text === 'undefined') return
    return slugify(text, {
      replacement, // replace spaces with replacement character, defaults to `-`
      remove: /[*+~.()'"!:@]/g, // remove characters that match regex, defaults to `undefined`
      lower: true, // convert to lower case, defaults to `false`
      strict: true, // strip special characters except replacement, defaults to `false`
      locale: 'vi' // language code of the locale to use
    })
  },
  isJSON (value) {
    try {
      JSON.parse(value)
      return true
    } catch (e) {
      return false
    }
  }
}
export default appUtils
